<template>
  <div>
    <!-- <Sidebar /> -->
    <HeaderPage />
    <div style="margin: 5em 0em 0em 1em">
      <BackComponent :title_name="$t('message.product-search')" />
    </div>
    <div style="margin-bottom: 5em">
      <v-container style="margin-top: 0px">
        <div v-if="isLoading">
          <v-row>
            <v-col
              v-for="i in 10"
              :key="i"
              cols="4"
              lg="2"
              md="2"
              sm="2"
              active="1"
              style="padding-bottom: 10px"
            >
              <Skeleton />
            </v-col>
          </v-row>
        </div>

        <div v-else>
          <v-container class="pb-1">
            <div
              v-if="itemSearch == null || itemSearch.length == 0"
              class="text-center d-flex flex-row align-center justify-center mt-12"
            >
              <h3 style="color: grey">{{ $t("message.no-product") }}</h3>
              <!-- <h3 style="color: grey">{{ itemSearch }}</h3> -->
            </div>
          </v-container>
          <v-row>
            <v-col
              v-for="list in itemSearch"
              :key="list.id"
              cols="4"
              class=""
              lg="2"
              md="3"
              sm="3"
              style="padding-bottom: 10px; padding-right: 4px; padding-left: 4px;"
            >
              <Product :data="list" @data-slug="getModal" :loading="isLoading" />
            </v-col>
          </v-row>
        </div>
        <div class="mb-4">
          <v-pagination
            v-if="!isLength"
            color="#ff0090"
            v-model="page"
            :length="last_page"
            :total-visible="5"
            class="mt-6"
            @input="changePage"
          ></v-pagination>
        </div>
      </v-container>
      <div style="margin-bottom: 8rem">
        <CategoryFooter />
      </div>
    </div>
    <Cashier />
    <Sidebar />
    <Modal
      :item_slug="slug"
      :showDialog="dialog"
      @get-modal="handleModal"
      @dialogVisible="dialog"
    />

    <ConfirmationProduct 
      :data_product="data_dialog_confirm"
      :showDialog="dialog_confirmation_product"
      @close="getCloseModal"
      @dialogVisible="dialog_confirmation_product"
    />
  </div>
</template>

<script>
import ConfirmationProduct from '../components/ConfirmationProduct.vue';
import Modal from "../components/DetailModal.vue"
import Sidebar from "../components/developmentv2/Sidebar.vue";
import HeaderPage from '../components/developmentv2/HeaderPage.vue'
import Product from "../components/Product.vue";
import Back from "../components/Back.vue";
import LoadingPage from "../components/LoadingPage.vue";
import Cashier from "../components/Cashier.vue";
import CategoryFooter from "../components/CategoryFooter.vue";
import Skeleton from "../components/skeleton/Skeleton.vue";

import { mdiArrowLeft } from "@mdi/js";

export default {
  name: "SearchResult",
  components: {
    Sidebar,
    Product,
    BackComponent: Back,
    LoadingPage,
    Cashier,
    Skeleton,
    CategoryFooter,
    HeaderPage,
    Modal,
    ConfirmationProduct
  },

  data() {
    return {
      isLoading: false,
      iconArrowLeft: mdiArrowLeft,
      page: 1,
      slug: "",
      dialog: false,
      dialog_confirmation_product: false,
      data_dialog_confirm: {},
    };
  },
  created() {
    this.dispatchItemSearch();
    this.clearStateSearch()
  },
  computed: {
    isLength() {
      return this.$store.state.itemList_module.is_length
    },
    itemSearch() {
      return this.$store.state.itemList_module.searchResult;
    },
    last_page() {
      return this.$store.state.itemList_module.search_item.last_page;
    },
  },
  methods: {
    getCloseModal() {
      this.dialog = false
      this.dialog_confirmation_product = false
    },
    handleModal(data) {
      if(data[0] == 'ConfirmProduct') {
        this.data_dialog_confirm = data[1]
        this.dialog_confirmation_product = true
      } else {
        this.dialog = false
      }
    },
    getModal(data) {
      if(typeof data === 'string') {
        this.slug = data;
        this.dialog = true;
      } else {
        this.data_dialog_confirm = data
        this.dialog_confirmation_product = true
      }
    },
    clearStateSearch() {
      this.$store.dispatch("itemList_module/clearStateSearch")
    },
    async dispatchItemSearch() {
      this.isLoading = true;
      await this.$store.dispatch("itemList_module/itemSearch");
      this.isLoading = false;
    },
    async changePage() {
      let query = this.$router.history.current.query.search
      this.isLoading = true;
      let slug = this.$router.history.current.params.slug;
      this.slug_child = slug;
      let res = await this.$store.dispatch("itemList_module/itemSearch", {
        page: this.page,
        keyword: query
      });
      this.isLoading = false;
      window.scrollTo(0, 0);
      return res;
    },
  },
};
</script>

<style>

.v-main__wrap {
  background-color: #ffffff;
  margin-top: 1px;
}
.item-class {
  background-color: transparent;
  min-width: 120px;
  min-height: 230px;
  margin-top: 24px;
  margin-left: 22px;
  word-wrap: break-word;
}
</style>
